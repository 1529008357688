import React from 'react';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router-dom';
import Menu from './components/Menu';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { IonContent, IonPage } from '@ionic/react';
import './pages/Page.css'
import TopNav from './components/TopNav';
import HeaderLogo from './components/headerLogo';
import Footer from './components/Footer';


/* Theme variables */
import './theme/variables.css';
import './theme/fonts.css';

/* Pages */
import ContactPage from './pages/Contact'
import HomePage from './pages/Home'
import AboutPage from './pages/AboutUs'
import ServicesPage from './pages/Services'
import BookingPage from './pages/Booking'
import ReviewsPage from './pages/Reviews'
import GetToKnowPage from './pages/GetToKnowPage'
import AmmoniaFreePage from './pages/AmmoniaFree'
import ExtensionsPage from './pages/ExtensionsPage'

setupIonicReact();



const App: React.FC = () => {
  return (
    <IonApp>
      <HeaderLogo/>

      <IonReactRouter>
      <Menu />

          <IonPage>
            <IonContent id="main">
            <section className='pageOverlay'>
              <HeaderLogo/>

              <TopNav></TopNav>

              <IonRouterOutlet id="main">
                <Route path={"/"} exact={true} component={HomePage}/>
                <Route path={"/about"} exact={true} component={AboutPage}/>
                <Route path={"/your-stylist"} exact={true} component={GetToKnowPage}/>
                <Route path={"/ammonia-free"} exact={true} component={AmmoniaFreePage}/>
                <Route path={"/services"} exact={true} component={ServicesPage}/>
                <Route path={"/booking"} exact={true} component={BookingPage}/>
                <Route path={"/reviews"} exact={true} component={ReviewsPage}/>
                <Route path={"/contact"} exact={true} component={ContactPage}/>
                <Route path={"/extensions"} exact={true} component={ExtensionsPage}/>
              </IonRouterOutlet>

            </section>

            </IonContent>
          </IonPage>
      </IonReactRouter>
      <Footer></Footer>
    </IonApp>
  );
};

export default App;
