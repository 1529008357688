import './Page.css';
import './AboutUs.css'
import ReserveButton from '../components/ReserveButton';


import React from "react";
import ImageGallery from '../components/ImageGallery';
import NewClient from '../components/NewGuests';

const HomePage: React.FC = () => {

    return (
        <React.Fragment>
            <h3>Your dream hair, untangled!</h3>
            <h1 className="aboutHeader">Welcome!</h1>
            <NewClient/>
            <ReserveButton/>&nbsp;
            <div className="imageContainer">
                <img loading="lazy" src="assets/images/d.jpg" alt="" />
            </div>
            <h2 className="message">Warm Regards From <br></br>The Owner</h2>
            <p>I created Studio D Luxe to make achieving your dream hair effortless. From an in-depth consultation tailored to you, to guiding you on how to maintain your new vibe, you'll find every moment delectable! You'll love the meticulous attention to detail not only in your hair but in how your new look complements your lifestyle. Experience our high-end, relaxed atmosphere, where you can enjoy a drink, and have plenty of laughs! Life is too short for a dull experience, so click the link above to start feeling fabulous now!</p>
            <p className="best">All The Best,</p>
            <div className="signature">Darlene "D" McLeod</div>
            <ImageGallery location={window.location.pathname}/>
            {/* <footer className="home"></footer> */}
        </React.Fragment>
    )
}

export default HomePage;