import React from 'react';
import './Contact.css';
import './Page.css';
import ImageGallery from '../components/ImageGallery';
import { contactData } from '../data/contact.data';
import { ClassNameData } from '../data/enums';
import ReserveButton from '../components/ReserveButton';

const ContactPage: React.FC = () => {
    return (
        <div className={ClassNameData.CONTACT_PAGE}>
            {/* <h1>Contact</h1>   */}
            <h3 className='tagline'>Your dream hair, untangled!</h3>
            
            
            {/* <div> */}
                {/* <p>To book, please call, text, or push the BOOK NOW button on the home page.</p> */}
                {/* <p><strong>New Guests</strong></p> */}
                {/* <p>When booking online, please add a complimentary consultation to your appointment so we have plenty of time to go over your hair goals!</p> */}
            {/* </div> */}
            <h1 className='location'>Location</h1>
            <div className="address">
                <p>{contactData.address.address}</p>
                <p>{contactData.address.address2}</p>
                <p>{contactData.address.city}, {contactData.address.state} {contactData.address.zip}</p>
            </div>&nbsp;
                <p>Studio D Luxe is located inside of Image Studios, Warson Woods.</p>
                <p>You can find image studios in a strip setback from Manchester Road with plenty of parking out front.</p>
                <p>When you enter image studios, suite 112 is on the right side of the hallway about 3/4 of the way in.</p>
                {/* <p>{contactData.address.name.substring(0,35)}</p> */}
                {/* <p>{contactData.address.name.substring(35,62)}</p> */}
           
            <h1>Call/Text</h1>
            <p>{contactData.info.phone}</p>
            <p className={ClassNameData.EMAIL}>{contactData.info.email}</p>
            <p className={ClassNameData.EMAIL}></p>
            <h1>Business Hours</h1>
            {contactData.hours.map((hours, index) => {
                if(hours.open) {
                    return(
                        <React.Fragment key={index}>
                            <p><b><strong>{hours.day} | {hours.start}-{hours.end}</strong></b></p>
                        </React.Fragment>
                    )    
                } else {
                    return(
                        <React.Fragment key={index}>
                            <p>{hours.day} | Closed</p>
                        </React.Fragment>
                    )    
                }
            })} 
            &nbsp;<p className={ClassNameData.WALK_INS}><strong>Appointment only</strong><br></br>
            &nbsp;Join waitlist for last minunte reservations</p>
            <ImageGallery location={window.location.pathname}/>
            <footer></footer>
        </div>
       
    )
    
}


export default ContactPage;