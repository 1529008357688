import React from "react";
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { galleryData } from "../data/gallery.data";

import './ImageGallery.css'

type GalleryProps = { location: string; }
let gallery: string[]

const ImageGallery: React.FC<GalleryProps> = (props) => {

    switch(props.location) {
        case "/":
            gallery = galleryData.homeGallery
            break;
        case "/contact":
            gallery = galleryData.contactGallery
            break;
        case "/reviews": 
            gallery = galleryData.reviewGallery
            break;
        case "/about": 
            gallery = galleryData.aboutGallery
            break;
    }

    return (
        <React.Fragment>
            <div className={"gallery"} >
                { gallery.map((galleryUrl, index) => {
                        return (
                            <div key={index} onClick={() => {

                                if (Capacitor.getPlatform() === 'ios' || 'android') {
                                    Browser.open({ url: galleryData.imageUrl+galleryUrl });
                                } else {
                                    window.open(galleryData.imageUrl+galleryUrl, '_self')
                                }
                            }}>
                                <img alt={props.location.replace('/', '')} loading="lazy" key={galleryUrl} className={"galleryImage"} src={galleryData.imageUrl + galleryUrl}/>
                            </div>

                        )
                    })}
            </div>
        </React.Fragment>
    );
  };
  
  export default ImageGallery;
  
