import React from "react";
import { IonButton } from "@ionic/react";


import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import './NewGuests.css'

export const NewClient: React.FC = () => {
    console.log('test NewGuest')
    const newLink: string = 'https://studiodluxestl.com/booking';

    return (
        <IonButton className={"newbtn"} size="small" onClick={() => {
            if (Capacitor.getPlatform() === 'ios' || 'android') {
                Browser.open({ url: newLink });
            } else {
                window.open(newLink, '_self')
            }
       }}>New Guests Start Here</IonButton>
    )
}

export default NewClient;