import React from "react";
import { IonButton, IonIcon, IonMenuButton } from "@ionic/react";
import { logoFacebook, logoInstagram } from 'ionicons/icons';
import './Footer.css'

const Footer: React.FC = () => {

    return (
        <span className={"pageFooter"}>
            <IonButton fill="clear" href="https://www.facebook.com/d.studiodluxe" target={'_blank'}>
                <IonIcon size='large' color='primary' slot="end" ios={logoFacebook} md={logoFacebook} />
            </IonButton>
            <IonButton fill="clear" href="https://www.instagram.com/d.studiodluxe/" target={'_blank'}>
                <IonIcon size='large' slot="end" ios={logoInstagram} md={logoInstagram} />
            </IonButton>
            <IonMenuButton />
        </span>
    )
}

export default Footer;