import React from "react"
import { IonButton } from '@ionic/react';
import './TopNav.css'
import { appPages } from "../data/menu.data";

  

const TopNav: React.FC = () => {
    return (
        <nav>
            {appPages.map((page, index) => {
                return (
                    <React.Fragment key={index}>
                        <IonButton fill='clear' routerLink={page.url}>{page.title}</IonButton>
                    </React.Fragment>
                )
            })}
        </nav>
    )
}

export default TopNav