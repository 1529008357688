interface Questionnaire {
    question: string | null;
    answer: string;
}
   
export const questionnaire: Questionnaire[] = [
    {       
            question: "What do you like to do for fun?",
            answer: "I like to go to different restaurants and bars to try the exact same meal and beverage at each location. Fish tacos and a strong cider please and thank you! ",
    },
    {
            question: "If you had the ability to invent anything in the world, what would it be?",
            answer: "An 1800 watt cordless blow dryer with a charge that lasted for 90 minutes.",
    },
    
    {       
            question: "If you could have any super power, what would it be and why?",
            answer: "Teleportation! I’m originally from Chicago, and have family all over the continent so being able to teleport would allow me to maximize my time with the ones I love.",
    },
    {       
            question: "What’s your favorite movie?",
            answer: "13 Going On 30!",
    },
    {       
            question: "What’s your survival plan during a zombie apocalypse?",
            answer: "Be SUPER DUPER nice to my boyfriend. He’s ready for one, and I don’t stand a chance without him.",
    }
]

export const ammoniaFreeQuestionnaire: Questionnaire[] = [
    {
        question: "What is ammonia free hair color and lightener?",
        answer: "Ammonia free hair color and lightener does the same job as the regular stuff, just in a less aggressive way; using a derivative of coconut called Cocamide MEA."
    }, 
    {
        question: "Why ammonia free?",
        answer: "As a hair stylist and a blonde babe, I’m always looking for the healthiest way to lift hair. Towards the end of 2022, I started touching up my blonde roots with an ammonia free lightener from ID Hair North America. I was excited to find a new way to get me super blonde, while still being kind to my hair. As the months went on and I continued to touch up my roots as they grew, I noticed that the texture of my hair was much calmer. From there, making the switch was a no brainer."
    }, 
    {
        question: "How does an ammonia free color appointment differ from a traditional one?",
        answer: "Because there are no ammonia products being used in the salon, you might notice a couple of changes.  There won’t be any chemical odor! Remember on the home page when I said calm is key? That includes your breathing."
    },
    {
        question: null,
        answer: "Your time in the salon may differ. If you have some sparkle to keep hidden, I might have you process a few extra minutes to ensure longevity in coverage. If you’re a blonde babe like me, I might have you process longer to get the brightness we’re trying to achieve. It all depends on what you come in with."
    },
    {
        question: null,
        answer: "Keep in mind that gentle and slow go hand and hand in this salon."
    }
]