import React from 'react';
import './GetToKnowPage.css';
import { ammoniaFreeQuestionnaire } from '../data/questionaire.data';

const AmmoniaFreePage: React.FC = () => {
    return (
        <React.Fragment>
            {/*<h1>Ammonia Free</h1>*/}
            <h4>Your dream hair, untangled!</h4>
            <div className="row">
                <div className="imageContainer2">
                    <img loading="lazy" src="assets/images/75.jpg" alt="" />
                </div>
                <div className="img-Container">
                    <img loading="lazy" src="assets/images/17.jpg" alt="" />
                </div>
                {/* <div className="imageContainer2">
                    <img loading="lazy" src="assets/images/70.jpg" alt="" />
                </div> */}
            </div>
            {ammoniaFreeQuestionnaire.map((question, index) => {
                    return (
                        <div key={index} className="questionWrapper">
                            { question.question ? <div className="questionContent">{question.question}</div> : null } 
                            <p className="answerContent">{question.answer}</p>
                        </div>
                    )
                    
                })
                
            } 
            {/* <footer></footer> */}
        </React.Fragment>
    )
}

export default AmmoniaFreePage;