import React from "react"
import './ExtensionsPage.css';

export const ExtensionsPage: React.FC = () => {

    return(
        <>
            <h3 className='tagline'>Your dream hair, untangled!</h3>
            <h1>Invisible Bead Extensions</h1>
            <div className="imageContainer">
                <img loading="lazy" src="assets/images/65.jpg" alt="" />
            </div>
                <p>As mentioned in the Get To Know Me section, I am certified in the Invisible Bead Hand Tied Extension (IBE) method.</p>
                <p>The Invisible Bead teqnique offers many amazing features, like the hidden beaded row! This means your extensions will blend seamlessly with your hair while it's in any style.</p>
                <p>Believe it or not, the seamless blend is not even IBE's number one priority. The most important thing we are taught, and the reason why I fell in love with the technique, is to focus on <strong>SCALP HEALTH!</strong> If you don't have a healthy scalp, you won't be able to support the extra hair on your head. </p>
                <p>There are many amazing things I can tell you about this technique and why it's the best out there for any head of hair, but I'm going to save that for your consultation. In the meantime, get some of your questions answered with the fun facts below.</p>
            <h2>How do I know if Invisible Bead Extensions <br></br> are what my look is missing?</h2>
                <p>When you put your hair in a bun, do you look like Miss Trunchbull?</p>
                <p>When your hair is down, do your clothes peek through your hair? </p>
                <p>If you have other extensions currently, can you see them when your hair is up, down, curled, straight, etc?</p>
                <p>Are you lacking fullness around your face?</p>
                <p>Is your hair a boring length?</p>
                <p>Do you want to incorporate color into your hair without commiting to actually coloring your hair?</p>
                <p>Did you cut your hair into a cute Hailey Bieber bob, but end up looking like Lord Farquad when you style it yourself?</p>
                <p>If you answered yes to any of these questions, it’s time to chat about Invisible Bead Extensions®</p><br></br>

            <h2>What to expect during your consultation</h2> 
            <div className="row">
                <div className="imageContainer">
                    <img loading="lazy" src="assets/images/63.jpg" alt="" />
                </div>
                <div className="imageContainer">
                    <img loading="lazy" src="assets/images/67.jpg" alt="" />
                </div>
            </div>
                <p>When you come into the salon for your consultation, we're going to talk about all the things. First things first, we will chat about color goals for your hair and for the extensions. I <strong>LOVE</strong> pictures so don't be afraid to bring ones you've seen for inspiration.</p>
                <p>From there we will figure out what kind of look you're going for with extensions. Do you want a little extra volume? Do you want long luscious locks? Both?</p>
                <p>We'll need to go over maintenence appointments and styling versatility as well. It's always nice to know what you're getting into beforhand, right?</p>
                <p>Next, we color match! I have swatches from many different extension lines so there is definitely something for everyone out there. But just in case, I can always custom color the hair to get the look of your dreams! Something to keep in mind: if your not happy with your current hair color, we will need to set up an appointment to adjust that before color matching for extensions.</p>
                <p>Last but not least, we'll discuss investment, and set up your install appointment!</p>
            <h2>What to expect during initial install appointment</h2>
            <div className="row">
                <div className="imageContainer">
                    <img loading="lazy" src="assets/images/37.jpeg" alt="" />
                </div>
                <div className="imageContainer">
                    <img loading="lazy" src="assets/images/73.jpg" alt="" />
                </div>
            </div>
                <p>Come to the salon ready to hang for a while and have a photo shoot once your hair is done. You'll want to dress comfortable, but cute. Neutral tones will compliment your after photos best.</p>
                <p>At this appointment you will also learn how to brush your hair with extensions in, and learn how to use all the products in your Extension Essentials Kit.</p>
                <p>Please ask any and all questions you can think of. I don't know what you don't know, and I want to set you up for success.</p>&nbsp;
            {/* <footer></footer> */}
            
        </>
    )
}


export default ExtensionsPage;