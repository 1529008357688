import React from "react";
import { IonButton } from "@ionic/react";


import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';

import './ReserveButton.css'

export const ReserveButton: React.FC = () => {

    const reserveLink: string = 'https://studiodluxe.glossgenius.com';

    return (
        <IonButton className={"resBtn"} size="small"  onClick={() => {
            if (Capacitor.getPlatform() === 'ios' || 'android') {
                Browser.open({ url: reserveLink });
            } else {
                window.open(reserveLink, '_self')
            }
       }}>Book Your Dream Hair Here</IonButton>
    )
}

export default ReserveButton;


