import React from "react";
import './AboutUs.css'

const AboutPage: React.FC = () => {

    return (
        <React.Fragment>
            <h1>What is Studio D Luxe?</h1>
            <p className="aboutContent">Studio D Luxe is a salon suite located inside of Image Studios, Warson Woods. A one chair salon if you will. What sets this salon apart from others is that Studio D Luxe is individual based. This means that when you’re here, the only focus is YOU! In the mean time, see the "Get To Know Me" tab where you can learn more about your next hairstylist. I look forward to making all of your hair dreams come true! xoxo-“D”</p>
        </React.Fragment>
    )
}

export default AboutPage;