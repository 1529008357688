import React from "react";
import './Services.css'
import { extensions } from "../data/services.data";
import { style } from "../data/services.data";
import { color } from "../data/services.data";
import { services } from "../data/services.data";

const ServicesPage: React.FC = () => {

    return (
        <React.Fragment>
            {/*<h1>Services</h1>*/}
            <h3>Your dream hair, untangled!</h3>
            <div className="row">
                <div className="style">
                    <img loading="lazy" src="assets/images/57.jpg" width="150" height="250"alt="" />
                </div>
                <div className="style">
                    <h2>Custom<br></br>Haircut<br></br>Services</h2>
                </div>
            </div>
            {/* <h3 className="pricelisttitle">Style</h3>&nbsp; */}
            {style.map((priceList, index) => {
                return (
                    <div key={index} className="pricelistcontainer">
                        <div className="priceliststyle">{priceList.cut}</div>
                        <div className="pricelistprice">{priceList.price}</div>&nbsp;
                    </div>                   
                )
            })}
            <br></br><br></br><br></br>
            <p>I am not accepting new appointments for the following services:</p>
            <div className="row">
                <div className="color">
                    <h2>Custom<br></br> Color<br></br> Services</h2>
                </div>
                <div className="color">
                    <img loading="lazy" src="assets/images/49.jpg" width="150" height="250"alt="" />
                </div>
            </div>&nbsp;
            {/* <h3 className="pricelisttitle">Color</h3>&nbsp; */}
            {color.map((priceList, index) => {
                return (     
                    <div key={index} className="pricelistcontainer">
                        <div className="priceliststyle">{priceList.cut}</div>
                        <div className="pricelistprice">{priceList.price}</div>
                        {/* <div className="pricelistdescription">{priceList.description}</div> */}
                    </div>                   

                )
            })}
            <br></br><br></br>
            <div className="colorprice"><p>All Chemical Services Include Integrity Insurance Treatment, and complimentary haircut and style.</p></div>
            <div className="row">
                <div className="extensions">
                    <img loading="lazy" src="assets/images/43.jpg" width="150" height="250"alt="" />
                </div>
                <div className="extentions">
                    <h2>Invisible<br></br> Bead <br></br>Extensions</h2>
                </div>
            </div>&nbsp;
            
           
            {extensions.map((priceList, index) => {
                return (     
                    <div key={index} className="pricelistcontainer">
                        <div className="priceliststyle">{priceList.cut}</div>
                        <div className="pricelistprice">{priceList.price}</div>
                     </div>            
                     
                )
                
            })}  
           
           <div className="extensionsprice"><p>Cost of Hair Not Included in Pricing</p></div>
            
            
            <br></br><br></br>
            <div className="policies">
            <h2>Financing</h2>
            <p>Studio D Luxe now has a buy now, pay later option! This allows you to finance services, products, and hair extensions for 3, 6, 12, or 24 months. With no hard credit check, you’re approved in 60 seconds! ($200 minimum to finance)</p>
            <p><a href='http://pay.withcherry.com/studiodluxestl'>Cick Here to Finance!</a></p>
            <h2>Payment Estimator</h2>
            <p>$500 finance <br></br>$71.43 due at appointment, $71.43 per month</p>
            <p>$1,000 finance <br></br>$142.86 due at appointment, $142.86 per month</p>
            <p>$1,500 finance <br></br> $214.29 due at appointment, $214.29 per month</p>
            <p>$2,000 finance <br></br> $285.71 due at appointment, $285.71 per month</p>
            <p>This is an example for 6 month financing options only. Exact terms and interest depend on credit score and other factors.</p>
                {/* <h1 className="policyHeader">Policies</h1> */}
                
            </div>
            {/* <footer></footer> */}
        </React.Fragment>
    )
}

export default ServicesPage;