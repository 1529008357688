import React from "react";
import ImageGallery from "../components/ImageGallery";

const ReviewsPage: React.FC = () => {

    return (
        <React.Fragment>
            {/* <h1>Reviews</h1> */}
            <h3 className='tagline'>Your dream hair, untangled!</h3>
            <ImageGallery location={window.location.pathname}/>
        </React.Fragment>
    )
}

export default ReviewsPage;