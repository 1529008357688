import React from "react";
import './Booking.css';
import ReserveButton from '../components/ReserveButton';
export const BookingPage: React.FC = () => {

return(
        <React.Fragment>
         <h1 className ='newguests'>New Guests</h1>
         <p>All appointments at Studio D Luxe are now exclusively available through our online booking platform. You can access this by clicking the button below. When reserving your first appointment, please include a complimentary consultation so we have plenty of time to map out your dream hair. If you have any questions, please see contact page for ways to reach out. </p>
         <p>Thank you for trusting me with your confidence, and I cannot wait to meet you!</p>
                {/*<p>To book online, click the button below. When booking online, please include a complimentary consultation to your first appointment so we have plenty of time to discuss your dream hair.</p> 
                <p>If you have any questions, please don't hesitate to reach out via phone, text message, email, or social media. </p>
                <p>Thank you for trusting me with your confidence, and I cannot wait to meet you!</p>&nbsp;*/}
                <ReserveButton/>
        <h1>Policies</h1>
        <h2>Cancellation/No show</h2>
                <p>Studio D Luxe has a 24 hour cancellation policy.
                    While I understand that life happens, this
                    business is appointment-based so please
                    communicate your time constraints accordingly.
                    If you need to cancel or reschedule during the 24-hour
                    window, you will be charged 25% of your scheduled appointment. </p>
                <p>If you miss your appointment with no prior communication, you will be charged 25% of your scheduled service and will no longer have the privilege of booking future services with Studio D Luxe.</p>
        <h2>Late Arrival</h2>
                <p>If you arrive more than 10 minutes late to your appointment, I may not be able to complete your service. If rescheduling is necessary, please communicate any time constraints clearly to avoid future delays.</p>
        </React.Fragment>
    )
}
        
        
        export default BookingPage;