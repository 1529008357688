export const contactData = {
    "address": {
        "name": "",
        "address": "9993 Manchester Road",
        "address2": "Suite 112",
        "city": "Saint Louis",
        "state": "Missouri",
        "zip": "63122"
    },
    "info": {
        "phone": "(314) 391-8450",
        "email": "info@studiodluxestl.com"
    },
    "hours": [
        {
            "day": "Monday",
            "start": "11am",
            "end": "9pm",
            "open": true,
        },
        {
            "day": "Tuesday",
            "start": "11am",
            "end": "9pm",
            "open": true,
        },
        {
            "day": "Wednesday",
            "start": "11am",
            "end": "9pm",
            "open": true,

        },
        {
            "day": "Thurday",
            "start": "11am",
            "end": "3pm",
            "open": true,

        },
        {
            "day": "Friday",
            "start": "11am",
            "end": "3pm",
            "open": true,

        },
        {
            "day": "Saturday",
            "start": "",
            "end": "",
            "open": false,

        },
        {
            "day": "Sunday",
            "start": "",
            "end": "",
            "open": false,

        }
    ]
}