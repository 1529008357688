import React from "react";
import './headerLogo.css'

export const HeaderLogo: React.FC = () => {

    return (
        <div>
            <img loading="lazy" className={"headerLogo"} src={"assets/images/logo/logo-removebg.png"} alt={"Studio D Luxe Logo"} />
        </div>
    )
}

export default HeaderLogo;